var render = function () {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _vm.title
    ? _c(
        "v-navigation-drawer",
        {
          directives: [
            {
              name: "scroll",
              rawName: "v-scroll:#scroll-target",
              value: _vm.onScroll,
              expression: "onScroll",
              arg: "#scroll-target",
            },
          ],
          class: _vm.appConfig.toc.elevation ? "elevation-3" : "",
          style: { opacity: _vm.appConfig.toc.opacity },
          attrs: {
            id: "default-toc",
            color:
              _vm.appConfig.toc.transparent &&
              _vm.appConfig.toc.fixed &&
              _vm.$vuetify.breakpoint.mdAndUp
                ? "transparent"
                : "toc",
            fixed: _vm.appConfig.toc.fixed || _vm.forceFixed,
            app: "",
            "mini-variant":
              _vm.mini && !_vm.appConfig.toc.fixed && !_vm.forceFixed,
            "expand-on-hover":
              _vm.mini && !_vm.appConfig.toc.fixed && !_vm.forceFixed,
            right: "",
            clipped: _vm.appConfig.toc.clipped,
            floating: _vm.appConfig.toc.floating,
            width: _vm.width,
          },
        },
        [
          _vm.appConfig.toc.actions > 0
            ? _c("PortalTarget", {
                staticClass: "pa-0 ma-0",
                attrs: { name: "page-tools" },
              })
            : _vm._e(),
          _vm.title
            ? _c(
                "v-list",
                { staticClass: "pt-0 pb-0 mt-0 mb-0", attrs: { dense: "" } },
                [
                  _c(
                    "v-list-item",
                    {
                      staticClass: "pt-0 pb-0 mt-0 mb-0 px-4",
                      attrs: { dense: "" },
                    },
                    [
                      _c("span", [_vm._v(" ")]),
                      _c(
                        "v-list-item-content",
                        [
                          _c(
                            "v-list-item-title",
                            { staticClass: "overflow-hidden text-no-wrap" },
                            [
                              _c("a", {
                                staticClass:
                                  "d-block transition-swing subtitle-1 font-weight-medium text-decoration-none",
                                domProps: { textContent: _vm._s(_vm.title) },
                                on: {
                                  click: function ($event) {
                                    $event.stopPropagation()
                                    $event.preventDefault()
                                    return _vm.onClick("toc$$-title$$")
                                  },
                                },
                              }),
                            ]
                          ),
                        ],
                        1
                      ),
                    ],
                    1
                  ),
                  _vm._l(_vm.tocLinks, function (item) {
                    return _c(
                      "v-list-item",
                      {
                        key: item.id,
                        staticClass: "pt-0 pb-0 mt-0 mb-0 px-8",
                        staticStyle: {
                          height: "25px",
                          "min-height": "25px",
                          "max-height": "25px",
                        },
                      },
                      [
                        _c(
                          "span",
                          {
                            staticClass: "toc-pipeline mx-auto",
                            class: {
                              "primary--text toc-link-active": item.isActive,
                            },
                            staticStyle: { height: "25px", width: "1px" },
                          },
                          [_vm._v(" ")]
                        ),
                        _c(
                          "v-list-item-title",
                          {
                            staticClass: "overflow-hidden text-no-wrap",
                            class: {
                              "primary--text toc-link-active": item.isActive,
                              "text--disabled": !item.isActive,
                              "pl-6": item.level === 1,
                              "pl-12": item.level === 2,
                              "pl-15": item.level === 3,
                            },
                          },
                          [
                            _c("a", {
                              staticClass:
                                "toc-text d-block transition-swing text-decoration-none subtitle-2",
                              domProps: { textContent: _vm._s(item.caption) },
                              on: {
                                click: function ($event) {
                                  $event.stopPropagation()
                                  $event.preventDefault()
                                  return _vm.onClick(item)
                                },
                              },
                            }),
                          ]
                        ),
                      ],
                      1
                    )
                  }),
                ],
                2
              )
            : _vm._e(),
        ],
        1
      )
    : _vm._e()
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }