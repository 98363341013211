var render = function () {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _vm.level > 0
    ? _c(
        "div",
        {
          staticClass: "toc-heading",
          attrs: {
            id: _vm.identifier,
            "data-toc-level": _vm.level > 3 ? 3 : _vm.level,
            "data-toc-id": _vm.identifier,
            "data-toc-caption": _vm.caption,
            "data-toc-is-title": _vm.title,
          },
        },
        [_vm._v(" ")]
      )
    : _vm._e()
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }